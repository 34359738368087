import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";

export default class Footer extends Component {
    render() {
        return (
            <div>

                <Router>
                <footer id="footer" className="px-lg-5 pt-40 nwel-footer" style={{background:'#f9f9f9'}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 px-lg-4">
                                <img src="assets/images/index/logo-dark.png" width="180" alt="Navana Welding Electrode Ltd."/>
                                <p className="mt-15">Manufacturing, Marketing & Selling of High Quality Welding Electrode</p>
                                <div className="social-icons">
                                <a href="https://www.facebook.com/NavanaWelding/" target="_blank" className="btn btn-facebook btn-circle"></a>
                                <a href="https://www.linkedin.com/company/navana-welding-electrode"  target="_blank" className="btn btn-linkedin btn-circle"></a>
                                <a href="https://www.instagram.com/navanawelding/"  target="_blank" className="btn btn-instagram btn-circle"></a>
                                </div>
                            </div>
                            <div className="col-lg-2 pr-lg-5 footer-info">
                                <h5>About</h5>
                                <ul className="mt-0 list-unstyled">
                                    <li><a href="/about">About NWEL</a></li>
                                    <li><a href="/quality-policy">Quality Policy</a></li>
                                    <li><a href="/top-management">Top Management</a></li>
                                    <li><a href="/contact">Contact us</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 px-lg-5 footer-info">
                                <h5>Products</h5>
                                <ul className="mt-0 list-unstyled">
                                <li><a href="/product">ARC Welding</a></li>
                                <li><a href="/mig-welding">MIG Welding</a></li>
                                <li><a href="/saw">SAW Welding</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 px-lg-6 footer-info">
                                <h5>Contact Us</h5>
                                <p>Navana Welding Electrode Ltd.</p>
                                <p className="mt-3"><i className="fas fa-map-marker-alt text-primary pr-2"></i>H # 10 (A), R # 90, Gulshan # 02. Dhaka, 1212</p>
                                <p><i className="fas fa-phone text-primary pr-2"></i> +880 9609004455 </p>
                                <p className="mb-5"><i className="far fa-envelope text-primary pr-2"></i> info@navanawelding.com </p>
                            </div>

                        </div>
                        <div className="row text-center">
                            <div className="col">
                                <span className="copyright">Design @ Development by Navana</span>
                            </div>
                        </div>
                    </div>
                </footer>
                </Router>
            </div>
           
        )
    }
}
