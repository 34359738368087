import React, { Component } from 'react'

export default class QualityPolicy extends Component {
    render() {
        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                        <h3 className="breadcrumb-title">Quality Policy</h3>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col">      
                                <div className="media bg-white shadow aos-init aos-animate" data-aos="fade-right">
                                    <div className="media-body p-5">
                                        <p className="lead">“Do it right at the first time”, the people and all elements of Navana Welding Electrode Ltd. (NWEL) stand committed to excellence and meeting customer expectation at competitive price 
                                        by manufacturing and marketing products as per the stated standards of the company.</p>
                                        <p className="lead">NWEL shall create an environment in which all employees at all levels take interest in pursuing the policy of perpetual improvement.</p>
                                        <p>By using highest quality of raw materials, NWEL assures superiority in quality and reliability of its each and every product.</p>
                                        <img src="assets/images/team/chairmen-sign.png" width="150" alt="Navana Welding Electrode Ltd."/>
                                        <p><strong>Shafiul Islam Kamal</strong> <br/>Chairman</p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
