import React, { Component } from 'react'

export default class OrderForm extends Component {
    render() {
        return (
            <div>
                <section id="cta" style={{background: "0% -36px rgb(25, 149, 199)"}} className="parallax pt-120 pb-120 text-white text-center">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                        {/* <h5 className="mb-3" data-aos="zoom-in" data-aos-delay="0">Be Awesome</h5> */}
                        <h3 className="mt-1" data-aos="zoom-in" data-aos-delay="100">Launch your Business the right way with Navana Welding!</h3>
                        <div className="text-center" data-aos="zoom-in" data-aos-delay="200">
                            <a className="btn btn-white btn-large" href="/order">Order Now</a>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        )
    }
}
