import React, { Component } from 'react'
import OrderForm from '../../slices/OrderForm';

export default class CieNiCuB extends Component {

    componentDidMount () {

        let scripts = [
            { src: "assets/js/magnific-popup.js" },
            { src: "assets/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })  

    }

    render() {

        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="breadcrumb-title"> Navana CI E-NiCuB</h3>
                        </div>
                    </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        <section id="works">
                            <div className="container">
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="works-grid--1cols">
                                        <div className="work-item pr-item">
                                            <div className="work-container">
                                                <img src="assets/images/products/nci.jpg" alt="Project Preview"/>
                                                <a className="work-mask image-link" href="assets/images/products/nci.jpg" data-popup-type="image">
                                                <div className="work-details">
                                                    <h5 className="work-title"><i className="fas fa-search-plus"></i></h5>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="page page-style-1 service-1 m-0">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark-cie">
                                                <tr>
                                                    <th scope="col" colSpan="3">Classification</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <th scope="row">AWS</th>
                                                <td>A 5.15:	:</td>
                                                <td>ENiCuB</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p><strong>Applications:</strong> <br/> This electrode is extensively suitable for repair of cast iron, joining cast iron on steel, filling up flaws, blow holes, pockets, rebuilding worn surfaces etc. It’s also well suitable for gears, machinery parts, pumps bodies etc
                                        </p>
                                        <p><strong>Characteristics:</strong> <br/> A light coated electrode with monel (Nickel-Copper alloy) core wire and graphite based coating for welding cast iron without pre-heating. This electrode can be operated at low current to ensure minimum dilution of the weld metal by the base metal and the arc should be directed on the weld puddle to ensure minimum hardness. The electrode has a smooth arc and the deposited weld is round and neat in appearance. The slag is very easily detached.
                                        </p>

                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-cie">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Chemical Analysis (%)</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">C</th>
                                                        <th>Mn</th>
                                                        <th>Si</th>
                                                        <th>P</th>
                                                        <th>S</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">0.800</th>
                                                        <td>0.740</td>
                                                        <td>0.70</td>
                                                        <td>0.003</td>
                                                        <td>0.002</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-cie">
                                                    <tr>
                                                        <th scope="col" colSpan="3">Others</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Storage</th>
                                                        <th>Current Condition</th>
                                                        <th>Welding Position ( All positions except vertical down)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Store in a dry place. Redrying at 110<sup>0</sup>C or 30 minutes is recommended if dampness is suspected.</th>
                                                        <td>AC Minimum OCV 50 or DC Electrode (+) (-)</td>
                                                        <td>Downhand and fillet</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-cie">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Packing Data</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Size (mm)</th>
                                                        <th>Length (mm)</th>
                                                        <th>Stick per Packet (+-2)</th>
                                                        <th>Kgs. per Packet</th>
                                                        <th>Current Range (Amp.)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>3.2</td>
                                                        <td>350</td>
                                                        <td>66</td>
                                                        <td>2</td>
                                                        <td>80-100</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4.0</td>
                                                        <td>350</td>
                                                        <td>64</td>
                                                        <td>3</td>
                                                        <td>100-130</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>

                                        <h6>Special Instructions</h6>
                                        <ul>
                                            <li>Clean the base metals thoroughly, make sure there is no rust, paint and water or grease.</li>
                                            <li>Keep the arc as short as possible.</li>
                                            <li>Follow the safety warning on the body of packet.</li>
                                        </ul>

                                    </div>
                                </div>


                            </div>
                            </div>
                        </section>
                        </div>
                    </div>
                    </div>
                <OrderForm/>
            </div>
        )
    }
}
