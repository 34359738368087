import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import {Link } from "react-router-dom";
import OrderForm from '../slices/OrderForm';


export default class Index extends Component {

    componentDidMount () {

        let scripts = [
            { src: "assets/js/magnific-popup.js" },
            { src: "assets/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })  

    }


    render() {

        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="breadcrumb-title"> ARC Welding</h3>
                        </div>
                    </div>
                    </div>
                </section>

                <section id="services" className="text-center">
                <div className="container">
                    <div className="row">
 
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up">
                            <Link to="/navana-arc">
                                <div className="service service-style-nwel text-center">
                                    <img className="img-fluid" src="assets/images/products/arc.jpg" alt="Navana ARC"/>
                                    <h5 className="service-title">Navana ARC</h5>
                                </div>
                            </Link>
                        </div>
        
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up" data-aos-delay="100">
                            <Link to="/bond-marine">
                            <div className="service service-style-nwel text-center">
                                <img className="img-fluid" src="assets/images/products/nbm.jpg" alt="Navana Bond Marine"/>
                                <h5 className="service-title"> Navana Bond Marine</h5>
                            </div>
                            </Link>
                        </div>
            
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up" data-aos-delay="200">
                            <Link to="/cieNiCuB">
                            <div className="service service-style-nwel text-center">
                                <img className="img-fluid" src="assets/images/products/nci.jpg" alt="Navana CI E-NiCuB"/>
                                <h5 className="service-title">Navana CI E-NiCuB </h5>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up" data-aos-delay="200">
                            <Link to="/hard350r">
                            <div className="service service-style-nwel text-center">
                                <img className="img-fluid" src="assets/images/products/h360.jpg" alt="Navana Hard 350R"/>
                                <h5 className="service-title">Navana Hard 350R</h5>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up" data-aos-delay="200">
                            <Link to="/hard650B">
                            <div className="service service-style-nwel text-center">
                                <img className="img-fluid" src="assets/images/products/h650.jpg" alt="Navana Hard 650B"/>
                                <h5 className="service-title">Navana Hard 650B </h5>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up" data-aos-delay="200">
                            <Link to="/pearl">
                            <div className="service service-style-nwel text-center">
                                <img className="img-fluid" src="assets/images/products/np.jpg" alt="Navana Pearl"/>
                                <h5 className="service-title">Navana Pearl </h5>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up" data-aos-delay="200">
                            <Link to="/spark">
                            <div className="service service-style-nwel text-center">
                                <img className="img-fluid" src="assets/images/products/ns.jpg" alt="Navana SPARK"/>
                                <h5 className="service-title">Navana SPARK </h5>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up" data-aos-delay="200">
                            <Link to="/wuperspeed">
                            <div className="service service-style-nwel text-center">
                                <img className="img-fluid" src="assets/images/products/navana-super-speed.png" alt="Navana Super Speed"/>
                                <h5 className="service-title">Navana Super Speed </h5>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up" data-aos-delay="200">
                            <Link to="/weld">
                            <div className="service service-style-nwel text-center">
                                <img className="img-fluid" src="assets/images/products/nw.jpg" alt="Navana Weld"/>
                                <h5 className="service-title">Navana Weld </h5>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 aos-init aos-animate " data-aos="fade-up" data-aos-delay="200">
                            <Link to="/Sse308L16">
                            <div className="service service-style-nwel text-center">
                                <img className="img-fluid" src="assets/images/products/SS-E-308L-16.jpg" alt="Navana SS E-308L-16"/>
                                <h5 className="service-title">Navana SS E-308L-16 </h5>
                            </div>
                            </Link>
                        </div>

                    </div>
                </div>
                </section>
                <OrderForm/>
            </div>
        )
    }
}