import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                        <h3 className="breadcrumb-title">About us</h3>
                        <p>Manufacturing, Marketing & Selling of High Quality Welding Electrode</p>
                        </div>
                    </div>
                    </div>
                </section>
                <section id="process" className="bg-light-grey">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-5 align-self-center">
                        {/* <h5 className="text-primary mb-3">Delivery</h5>
                        <h3 className="mb-5 mt-3">The process</h3> */}
        
                        <div className="media bg-white shadow" data-aos="fade-right">
                            <div className="media-body">
                            <h5 className="text-primary">1. Industry/ Business Type</h5>
                            <p>Manufacturing, Marketing & Selling of High Quality Welding Electrode</p>
                            </div>
                        </div>
           
                        <div className="media bg-white shadow" data-aos="fade-right">
                            <div className="media-body">
                            <h5 className="text-primary">2. Number of Employees</h5>
                            <p>Total 220 nos.</p>
                            </div>
                        </div>
                        <div className="media bg-white shadow" data-aos="fade-right">
                            <div className="media-body">
                            <h5 className="text-primary">3. Contacts</h5>
                            <p className="mt-3"><i className="fas fa-map-marker-alt text-primary pr-2"></i> H # 10A, R # 90, Gulshan - 2, Dhaka-1212 </p>
                            <p><i class="fas fa-phone text-primary pr-2"></i>  +880 9609004455</p>
                            </div>
                        </div>
                        </div>
                        <div className=" col-lg-7 align-self-center" data-aos="fade-left" data-aos-delay="400">
                            <img src="assets/images/success_way.png" className="img-fluid" alt="Process"/>
                        </div>
                    </div>
                    </div>
                </section>
                <section id="about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                                <h3 className="mb-1 mt-3">About Welding Electrode:</h3>
                                <p>In the year 2011 Navana Welding Electrode Ltd. formed as a subsidiary company of Navana CNG Limited, 
                                under the flagship of renowned Navana Group. The company has plant with an area of 72000 square feet (approx.)
                                 and 81 nos. employees for manufacturing, marketing & selling of high quality welding electrodes under the brand names of Navana Welding 
                                 Electrode that are geared for both domestic and overseas markets.</p>

                                 <p> A metal or alloy in rod or wire forms used in electric arc welding to maintain the arc and at the same time supply molten metal or alloy at the point where the weld is to be accomplished.</p>
                                 <p> One of the most common types of arc welding is shielded metal arc welding, which is also known as manual metal arc welding (MMAW) or stick welding. Arc welding uses a welding power supply to create an electric arc between an electrode and the base material to melt the metals at the welding point.</p>
                                 <p>Simplicity and portability of MMAW equipment enable welders to weld indoors and out, on production lines, ships, bridges, buildings frames, oil refineries, offshore rigs and cross country pipelines. By using diesel or gas powered generators, welders can work in remote areas, such as farms and mines, construction and repair jobs inaccessible to other welding processes. Vehicles, ships, bridges and all other necessities of life cannot be constructed without welding electrode. Welding workshops also use MMAW to join carbon, alloy and stainless steels; cast iron, heat and corrosion resistant alloys. Welders can reach joints in any position, in corners and over-head, even in blind zones. For on site work, often the situation for maintenance and repair jobs, MMAW can't be beat.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light-grey">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="0">
                                <div className="service service-style-2">
                                    <i className="fa fa-leaf text-primary"></i>
                                    <h5 className="service-title">Vision</h5>
                                    <span className="service-text">The vision of the company is ”to exceed customer expectation by providing complete welding solution and be the best in the eyes of our customer”</span>
                                </div>
                            </div>

                            <div className="col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="service service-style-2">
                                    <i className="fa fa-leaf text-primary"></i>
                                    <h5 className="service-title">Mission</h5>
                                    <span className="service-text">Our mission is ”to be recognized as leading welding electrode company in the country for its innovative product line, timely delivery and best quality customer service with competitive price” with a Slogan of</span>
                                    <h4>“Bonding Metal With Ideas”</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        )
    }
}
