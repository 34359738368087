import React, { Component } from 'react'
import axios from 'axios';
import Mapnwel from '../slices/MapNwel';

export default class Contact extends Component {
    
    constructor() {
        super();
        this.oncHangeHandler = this.oncHangeHandler.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            error:''
        }
        
    }
    
    oncHangeHandler = (e) => {
        e.preventDefault();
        var inputName = e.target.name;
        var inputValue = e.target.value;
        this.setState({ [inputName]: inputValue });

    }

    handleFormSubmit = (event) => {

        event.preventDefault();

        const data = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            subject: this.state.subject,
            message: this.state.message
        }

        const config = {     
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        }
        axios.post('http://localhost:8000/api/contact/store', data, config)
            .then(Response => {
                console.log(Response.data);
            })
            .catch(error => {
                //console.log(error.response.data.errors['name']);
                this.setState({error:error.response.data.errors});
                alert('somethings is missing');
            })

    }


    render() {
        return (
            <div>

                <section id="map1" className="p-0 pt-80 mt-10 map-nwel">
                    <div className="container-fluid p-0">
                        <Mapnwel />
                    </div>
                </section>

                <section id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">

                                <div className="media p-5 bg-white shadow" data-aos="fade-right">
                                    <div className="media-body">
                                        <h3 className="mt-3 mb-4">Contact us</h3>
                                        <p>Don't hesitate to contact us. We'll be glad to assist you!</p>
                                        <p className="mt-3"><i className="fas fa-map-marker-alt text-primary pr-2"></i>  H # 10 (A), R # 90, Gulshan # 02. Dhaka, 1212 </p>
                                        <p><i className="fas fa-phone text-primary pr-2"></i>  +880 9609004455</p>
                                        <p><i className="fas fa-calendar-alt text-primary pr-2"></i> Monday - Friday, from 9pm - 5pm  </p>
                                        <p className="mb-5"><i className="far fa-envelope text-primary pr-2"></i> info@navanawelding.com</p>
                                    </div>
                                </div>
                            </div>

                            <div className="offset-lg-1 col-lg-6">
                                <h2 className="hide">Contact</h2>
                                <form>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="name"
                                            name="name"
                                            onChange={this.oncHangeHandler} />
                                            <span className="alert-danger">{this.state.error['name']}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="email"
                                            name="email"
                                            onChange={this.oncHangeHandler} />
                                            <span className="alert-danger">{this.state.error['email']}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="tel" className="form-control" placeholder="Phone"
                                            name="phone"
                                            onChange={this.oncHangeHandler} />
                                            <span className="alert-danger">{this.state.error['phone']}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="subject"
                                            name="subject"
                                            onChange={this.oncHangeHandler} />
                                            <span className="alert-danger">{this.state.error['subject']}</span>
                                    </div>
                                    <div className="form-group">
                                        <textarea placeholder="message" className="form-control" rows="3"
                                            name="message"
                                            onChange={this.oncHangeHandler}>
                                        </textarea>
                                        <span className="alert-danger">{this.state.error['message']}</span>
                                    </div>
                                    <button type="button" onClick={(e) => this.handleFormSubmit(e)} className="btn btn-large btn-secondary">Submit</button>
                                </form>

                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
