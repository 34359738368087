import React, { Component } from 'react';
import axios from 'axios';

export default class Career extends Component {
    constructor() {
        super();
        this.oncHangeHandler = this.oncHangeHandler.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = {
            name: '',
            email: '',
            phone: '',
            position: '',
            message: '',
            value:'',
            resume: '',
            error:''
        }
        
    }
    
    oncHangeHandler = (e) => {
        e.preventDefault();
        var inputName = e.target.name;
        var inputValue = e.target.value;
        this.setState({ [inputName]: inputValue });

    }

    onFileChange = (e) =>{
        e.preventDefault();
        this.setState({ resume: e.target.files[0] }); 
        console.log(this.state.resume);
    }

    handleFormSubmit = (event) => {

        event.preventDefault();

        const data = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            position: this.state.position,
            message: this.state.message,
            resume: this.state.resume.name,
        }

        axios.post('http://localhost:8000/api/career/store', data)
            .then(Response => {
                console.log(Response.data);
            })
            .catch(error => {
                console.log(error);
                //this.setState({error:error.response.data.errors});
                //alert('somethings is missing');
            })

    }

    render() {
        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                        <h3 className="breadcrumb-title">Build Career with <br/> Navana Welding Electrode Ltd.</h3>
                        <p>If you are looking for a job at Navana Welding Electrode Ltd, you can submit your resume here.</p>
                        </div>
                    </div>
                    </div>
                </section>
                <section id="contact">
                    <div className="container">
                    <div className="row">

                        <div className="offset-lg-3 col-lg-6">
                        <h2 className="hide">Contact</h2>

                            <form>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="name"
                                        name="name"
                                        onChange={this.oncHangeHandler} />
                                        <span className="alert-danger">{this.state.error['name']} {this.state.name}</span>
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="email"
                                        name="email"
                                        onChange={this.oncHangeHandler} />
                                        <span className="alert-danger">{this.state.error['email']}</span>
                                </div>
                                <div className="form-group">
                                    <input type="tel" className="form-control" placeholder="Phone"
                                        name="phone"
                                        onChange={this.oncHangeHandler} />
                                        <span className="alert-danger">{this.state.error['phone']}</span>
                                </div>
                                <div className="form-group">
                                    <select style={{padding: "10px", height: "calc(2.5em + 0.75rem + 2px)"}} className="form-control" name="position" onChange={this.oncHangeHandler} value={this.state.postion}
                                    >
                                        <option value="">Select job type</option>
                                        <option value="1">Project Engineer</option>
                                        <option value="2">Executive / Seniior Executive</option>
                                    </select>
                                    <span>{console.log(this.state.name )}</span>
                                </div>
                                <div className="form-group">
                                    <textarea placeholder="message" className="form-control" rows="3"
                                        name="message"
                                        onChange={this.oncHangeHandler}>
                                    </textarea>
                                    <span className="alert-danger">{this.state.error['message']}</span>
                                </div>
                                <div className="form-group">
                                    <input type="file" name="resume" onChange={this.onFileChange} className="form-control-file" id="resume"/>
                                </div>
                                <button type="button" onClick={(e) => this.handleFormSubmit(e)} className="btn btn-large btn-secondary">Submit</button>
                            </form>

                        </div>
                    </div>
                    </div>
                </section>
            </div>
        )
    }
}
