import React, { Component } from 'react';
import OrderForm from '../slices/OrderForm';

export default class Order extends Component {
    render() {
        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                        <h3 className="breadcrumb-title">Order Now</h3>
                        </div>
                    </div>
                    </div>
                </section>

                <section id="contact">
                    <div className="container">
                    <div className="row">
            
                        <div className="col-lg-5">

                        <div className="media p-5 bg-white shadow" data-aos="fade-right">
                            <div className="media-body">
                            <h3 className="mt-3 mb-4">Contact us</h3>
                            <p>Don't hesitate to contact us. We'll be glad to assist you!</p>
                            <p className="mt-3"><i className="fas fa-map-marker-alt text-primary pr-2"></i> H # 10 (A), R # 90, Gulshan # 02. Dhaka, 1212</p>
                            <p><i className="fas fa-phone text-primary pr-2"></i> +880 9609004455</p>
                            <p><i className="fas fa-calendar-alt text-primary pr-2"></i> Monday - Friday, from 9pm - 5pm  </p>
                            <p className="mb-5"><i className="far fa-envelope text-primary pr-2"></i> info@navanawelding.com</p>
                            </div>
                        </div>
                        </div>

                        <div className="offset-lg-1 col-lg-6">
                        <h2 className="hide">Contact</h2>
                        <form>
                            <div className="form-group">
                            <input type="text" className="form-control" id="NameInput" placeholder="name"/>
                            </div>
                            <div className="form-group">
                            <input type="email" className="form-control" id="EmailInput" placeholder="email"/>
                            </div>
                            <div className="form-group">
                            <input type="text" className="form-control" id="SubjectInput" placeholder="phone"/>
                            </div>
                            <div className="form-group">
                                <select style={{padding: "10px", height: "calc(2.5em + 0.75rem + 2px)"}} className="form-control" name="district"
                                >
                                    <option value="">Select District</option>
                                    <option value="Dhaka">Dhaka</option>
                                    <option value="Comilla">Comilla</option>
                                    <option value="Chittagong">Chittagong</option>
                                </select>
                                <span></span>
                            </div>
                            <div className="form-group">
                                <select style={{padding: "10px", height: "calc(2.5em + 0.75rem + 2px)"}} className="form-control" name="address"
                                >
                                    <option value="">Select Product</option>
                                    <option value="ARC Welding">ARC Welding</option>
                                    <option value="MIG Welding">MIG Welding</option>
                                    <option value="SAW Welding">SAW Welding</option>
                                </select>
                                <span></span>
                            </div>
                            <div className="form-group">
                                <textarea placeholder="Address" className="form-control" id="FormControlTextarea1" rows="3"></textarea>
                            </div>
                            <button type="submit" className="btn btn-large btn-secondary">Submit</button>
                        </form>
                        </div>
                    </div>
                    </div>
                </section>
                <OrderForm/>
            </div>
        )
    }
}
