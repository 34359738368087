import React, { Component } from 'react'

export default class Gallery extends Component {
    render() {
        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                        <h3 className="breadcrumb-title">Photo Gallery</h3>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        )
    }
}
