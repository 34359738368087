import React, { Component } from 'react'

export default class News extends Component {
    render() {
        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                        <h3 className="breadcrumb-title">News</h3>
                        <p>If you are looking for a job at Navana Welding Electrode Ltd, you can submit your resume here.</p>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        )
    }
}
