import React, { Component } from 'react'
import { BrowserRouter as Router, Route,Switch, Link } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import MyRoute from './components/Myroute'
import ScrollToTop from './components/ScrollToTop'

export default class App extends Component {
  render() {
    return (
      <div>
          <Router>
            <ScrollToTop>
              <Header/>
              <MyRoute/>
              <Footer/>
            </ScrollToTop>
          </Router>
      </div>
    )
  }
}
