import React, { Component } from 'react'
import Mapnwel from '../slices/MapNwel';
import OrderForm from '../slices/OrderForm';

export default class Network extends Component {
    render() {
        return (
            <div>
                <section id="map1" className="p-0 pt-80 mt-10 map-nwel">
                    <div className="container-fluid p-0">
                        <Mapnwel/>
                    </div>
                </section>

                <section className="text-center">
                    <div className="container-fluid">
                        <div className="row mb-3 justify-content-center">
                            <div className="col-4 align-self-center">
                                <div className="service service-style-5 bg-squared">
                                    <h5 className="service-title">National Sales Manager</h5>
                                    <span className="service-text">Phone: +8801819396663</span>
                                    <span className="service-text">Email: mizanur@navanawelding.com</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="service service-style-5 bg-squared minhieght-245">
                                    <h5 className="service-title">Divisional Manager</h5>
                                    <p>Sales (Dhaka North, Rajshahi & Rangpur)</p>
                                    <span className="service-text">Phone: +8801833326616</span>
                                    <span className="service-text">Email: mannan@navanawelding.com</span>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="service service-style-5 bg-squared minhieght-245">
                                    <h5 className="service-title">Divisional Manager</h5>
                                    <p>Sales (Dhaka South, Sylhet & Khulna)</p>
                                    <span className="service-text">Phone: +8801819404442</span>
                                    <span className="service-text">Email: rakesh@navanawelding.com</span>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="service service-style-5 bg-squared minhieght-245">
                                    <h5 className="service-title">Divisional Manager</h5>
                                    <p>Sales (Chittagong & Barisal)</p>
                                    <span className="service-text">Phone: +8801833326602</span>
                                    <span className="service-text">Email: asadul@navanawelding.com</span>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="service service-style-5 bg-squared minhieght-245">
                                    <h5 className="service-title">Corporate Manager</h5>
                                    <span className="service-text">Phone: +8801833326675</span>
                                    <span className="service-text">Email: mozumder@navanawelding.com</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <OrderForm/>
            </div>
        )
    }
}
