import React, { Component } from 'react';
import OrderForm from '../../slices/OrderForm';

export default class Pearl extends Component {

    componentDidMount () {

        let scripts = [
            { src: "assets/js/magnific-popup.js" },
            { src: "assets/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })  

    }

    render() {

        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="breadcrumb-title"> Navana Pearl </h3>
                        </div>
                    </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        <section id="works">
                            <div className="container">
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="works-grid--1cols">
                                        <div className="work-item pr-item">
                                            <div className="work-container">
                                                <img src="assets/images/products/np.jpg" alt="Project Preview"/>
                                                <a className="work-mask image-link" href="assets/images/products/np.jpg" data-popup-type="image">
                                                <div className="work-details">
                                                    <h5 className="work-title"><i className="fas fa-search-plus"></i></h5>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="page page-style-1 service-1 m-0">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark-np">
                                                <tr>
                                                    <th scope="col" colSpan="3">Classification</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <th scope="row">AWS</th>
                                                <td>A 5.1:</td>
                                                <td>E 7018</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p><strong>Applications:</strong> <br/> Navana pearl can be used for welding MS of heavy sections, highly restrained joints, medium & high carbon steel, medium tensile structural steel, low alloy engineering steel, low temperature notch, tough steel, cast steel, cast iron etc.
                                        </p>
                                        <p><strong>Characteristics:</strong> <br/> A heavy coated basic type low Hydrogen electrode to operate in all positions. Very smooth arc, medium penetration, low spatter and easy slag removal are the main features of this electrode. The weld metal is ductile and crack resistant.</p>

                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-np">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Chemical Analysis (%)</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">C</th>
                                                        <th>Mn</th>
                                                        <th>Si</th>
                                                        <th>P</th>
                                                        <th>S</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">0.08</th>
                                                        <td>1.10</td>
                                                        <td>0.35</td>
                                                        <td>0.018</td>
                                                        <td>0.020</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-np">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Mechanical Properties</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Yield Strength</th>
                                                        <th>Tensile Strength</th>
                                                        <th>Reduction in Area</th>
                                                        <th>Elongation</th>
                                                        <th>Impact ( Charpy V Notch)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">465  N/mm <sup>2</sup></th>
                                                        <td>530  N/mm <sup>2</sup></td>
                                                        <td>75%</td>
                                                        <td>32%	</td>
                                                        <td>130 Joules at minus 20 <sub>0</sub></td>
        
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-np">
                                                    <tr>
                                                        <th scope="col" colSpan="3">Others</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Storage</th>
                                                        <th>Current Condition</th>
                                                        <th>Welding Position ( All positions except vertical down)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Store in a dry place and redry immediately before use depending on maximum permissible Hydrogen allowable in deposited weld</th>
                                                        <td>AC Minimum OCV 70 or DC Electrode (+/-)</td>
                                                        <td><img src="assets/images/icon.png" alt=""/></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-np">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Packing Data</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Size (mm)</th>
                                                        <th>Length (mm)</th>
                                                        <th>Stick per Packet (+-2)</th>
                                                        <th>Kgs. per Packet</th>
                                                        <th>Current Range (Amp.)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>2.5</td>
                                                        <td>350</td>
                                                        <td>110</td>
                                                        <td>2</td>
                                                        <td>60-85</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3.2</td>
                                                        <td>350</td>
                                                        <td>81</td>
                                                        <td>3</td>
                                                        <td>100-140</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4.0</td>
                                                        <td>350</td>
                                                        <td>57</td>
                                                        <td>3</td>
                                                        <td>140-190</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>

                                        <h6>Special Instructions</h6>
                                        <ul>
                                            <li>Clean the base metals thoroughly, make sure there is no rust, paint and water or grease.</li>
                                            <li>Keep the arc as short as possible.</li>
                                            <li>Follow the safety warning on the body of packet.</li>
                                        </ul>

                                    </div>
                                </div>


                            </div>
                            </div>
                        </section>
                        </div>
                    </div>
                    </div>
                <OrderForm/>
            </div>
        )
    }
}
