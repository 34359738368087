import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
    width: '100%',
    height: '100%'
  }

class MapNwel extends Component {
    render() {
        return (
                
                 <Map
                    google={this.props.google}
                    zoom={17}
                    style={mapStyles}
                    initialCenter={{ lat: 23.796754867082697, lng: 90.41532384304232 }}>
                    <Marker position={{ lat: 23.796754867082697, lng: 90.41532384304232 }} />  
                </Map>
  
        )
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBf12PbZrun26rrN3EtOiSng4bMMHS3roM'
  })(MapNwel);
