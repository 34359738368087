import React, { Component } from 'react'
import OrderForm from '../slices/OrderForm';

export default class Saw extends Component {
    render() {
        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="breadcrumb-title">SAW</h3>
                        </div>
                    </div>
                    </div>
                </section>
                <section>
                    <div className="container">

                        <div className="row post">
                            <div className="col-4 post-image">
                                <img className="img-fluid" src="assets/images/products/saw.jpg" alt="post"/>
                            </div>
                            <div className="col-8">
                                <p style={{margin:0, padding:0}}>Submerged-Arc Welding is mostly used as a fully-automatic process and sometimes as a semi-automatic process.</p>
                                <p>The electrode is a continuous metallic wire (solid or flux-cored) in the form of a spool or a coil. It is fed automatically into the arc at a constant speed. The arc is covered with a layer of dry granular flux which performs the same functions as the coating of a manual electrode. The arc length is automatically controlled.</p>
                                <p> This process gives very high productivity and excellent weld quality.<br/>
                                It is generally applied on mild steel, high tensile steels, low-alloy and stainless steel. It is also used for the fabrication of plate grinders, pressure vessels, pipes and penstocks for surfacing and strip cladding.</p>
                            </div>

                        </div>

                    </div>
                </section>
                <OrderForm/>
            </div>
        )
    }
}
