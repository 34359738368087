import React, { Component } from 'react'
import OrderForm from '../../slices/OrderForm';

export default class Sse308L16 extends Component {

    componentDidMount () {

        let scripts = [
            { src: "assets/js/magnific-popup.js" },
            { src: "assets/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })  

    }

    render() {

        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="breadcrumb-title"> Navana SS E-308L-16 </h3>
                        </div>
                    </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        <section id="works">
                            <div className="container">
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="works-grid--1cols">
                                        <div className="work-item pr-item">
                                            <div className="work-container">
                                                <img src="assets/images/products/SS-E-308L-16.jpg" alt="Project Preview"/>
                                                <a className="work-mask image-link" href="assets/images/products/SS-E-308L-16.jpg" data-popup-type="image">
                                                <div className="work-details">
                                                    <h5 className="work-title"><i className="fas fa-search-plus"></i></h5>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="page page-style-1 service-1 m-0">

                                        <p><strong>Applications:</strong> <br/> This electrode is suitable for chromium- Nickel steels with low or medium carbon content in household articles, hospital equipment, chemical and fertilizer plants, paper and pulp industries, gas turbines, food industries etc.
                                        </p>
                                        <p><strong>Characteristics:</strong> <br/> A corrosion resistance extra low carbon austenitic electrode of the chromium, nickel type suitable for welding of all 18/8 ELC grades of stainless steel. The electrode is suitable for multi pass welding of AISI 308 and 308L stainless steel in all positions. This electrode is excellent for slag removal and produced weld metal has regular and uniform appearance with fine ripple and smooth junction.</p>

                                        <h6>Special Instructions</h6>
                                        <ul>
                                            <li>Clean the base metals thoroughly, make sure there is no rust, paint and water or grease.</li>
                                            <li>Keep the arc as short as possible.</li>
                                            <li>Follow the safety warning on the body of packet.</li>
                                        </ul>

                                    </div>
                                </div>


                            </div>
                            </div>
                        </section>
                        </div>
                    </div>
                    </div>
                <OrderForm/>
            </div>
        )
    }
}
