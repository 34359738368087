import React, { Component } from 'react'
import OrderForm from '../../slices/OrderForm';

export default class Hard650B extends Component {

    componentDidMount () {

        let scripts = [
            { src: "assets/js/magnific-popup.js" },
            { src: "assets/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })  

    }

    render() {

        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="breadcrumb-title"> Navana Hard 650B </h3>
                        </div>
                    </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        <section id="works">
                            <div className="container">
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="works-grid--1cols">
                                        <div className="work-item pr-item">
                                            <div className="work-container">
                                                <img src="assets/images/products/h650.jpg" alt="Project Preview"/>
                                                <a className="work-mask image-link" href="assets/images/products/h650.jpg" data-popup-type="image">
                                                <div className="work-details">
                                                    <h5 className="work-title"><i className="fas fa-search-plus"></i></h5>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="page page-style-1 service-1 m-0">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark-h650">
                                                <tr>
                                                    <th scope="col">Hardness</th>
                                                    <th scope="col">600-700 HV(non machinable)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                        <p><strong>Applications:</strong> <br/> The typical applications of this electrode are welding in grader &doser blades, earth scoops bucket lips. Grousers, conveyor screws, crusher jaws, pulping knives, post hole auger flutes, agricultural points etc.
                                        </p>
                                        <p><strong>Characteristics:</strong> <br/> A heavy coated hydrogen controlled basic type, iron powder electrode, specially designed to use on high carbon and high Sulphur steels without giving under bead cracking. The weld deposits are air hardening chromium manganese carbon steel for resisting high abrasion with moderate impact. This alloy is one of the hardest that can be deposited free of relief checks under normal welding conditions. The electrode produces smooth & stable arc, medium penetration and least spatter and easy slag detachable. The basic flux coating gives excellent resistance to rust, mill scale, dirt and oil on the surface being hard-faced.</p>

                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-h650">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Chemical Analysis (%)</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">C</th>
                                                        <th>Mn</th>
                                                        <th>Si</th>
                                                        <th>P</th>
                                                        <th>S</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">2.70</th>
                                                        <td>1.45</td>
                                                        <td>1.250</td>
                                                        <td>0.015</td>
                                                        <td>0.007</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-h650">
                                                    <tr>
                                                        <th scope="col" colSpan="3">Others</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Storage</th>
                                                        <th>Current Condition</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Store in a dry place. Redrying at 110<sup>0</sup>C for  30 minutes is recommended if dampness is suspected.</th>
                                                        <td>AC Minimum OCV 70 or DC Electrode (+)</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-h650">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Packing Data</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Size (mm)</th>
                                                        <th>Length (mm)</th>
                                                        <th>Stick per Packet (+-2)</th>
                                                        <th>Kgs. per Packet</th>
                                                        <th>Current Range (Amp.)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>3.2</td>
                                                        <td>350</td>
                                                        <td>100</td>
                                                        <td>3</td>
                                                        <td>90-135</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4.0</td>
                                                        <td>350</td>
                                                        <td>65</td>
                                                        <td>3</td>
                                                        <td>125-180</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>

                                        <h6>Special Instructions</h6>
                                        <ul>
                                            <li>Clean the base metals thoroughly, make sure there is no rust, paint and water or grease.</li>
                                            <li>Keep the arc as short as possible.</li>
                                            <li>Follow the safety warning on the body of packet.</li>
                                        </ul>

                                    </div>
                                </div>


                            </div>
                            </div>
                        </section>
                        </div>
                    </div>
                    </div>
            <OrderForm/>
            </div>
        )
    }
}
