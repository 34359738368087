import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route,Switch, Link } from "react-router-dom";

export default class Header extends Component {
    render() {
        return (
    
            <div>
                <header className="nav-style-1">      
                    <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white px-lg-5 nwel-header">
                        <Link className="navbar-brand" to="/">
                            <img src="assets/images/index/logo-dark.png" width="150" alt="Navana Welding Electrode Ltd."/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto mr-auto">
                            <li className="nav-item dropdown active">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About Us</Link>
                                <div className="dropdown-menu" aria-labelledby="pagesDropdown">
                                    <Link className="dropdown-item" to="/about">About NWEL</Link>
                                    <Link className="dropdown-item" to="/quality-policy">Quality Policy</Link>
                                    <Link className="dropdown-item" to="/top-management">Top Management</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Product</Link>
                                <div className="dropdown-menu" aria-labelledby="pagesDropdown">
                                    <Link className="dropdown-item" to="/product">ARC Welding</Link>
                                    <Link className="dropdown-item" to="/mig-welding">MIG Welding</Link>
                                    <Link className="dropdown-item" to="/saw">SAW</Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/market-network">Market Network</Link>
                            </li>
                            <li className="nav-item">
                            <Link className="nav-link" to="/order">Ordering</Link>
                            </li>
                            {/* <li className="nav-item">
                            <Link className="nav-link" to="/news">News</Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/photo-gallery">Photos</Link>
                            </li> */}
                            <li className="nav-item">
                            <Link className="nav-link" to="/career">Career</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Contact</Link>
                            </li>

                        </ul>
                        </div>
                        <div class="social-icons d-none d-xl-block">
                            <a href="tel:+880 9609004455" class="btn btn-small btn-outline fw_800">Hotline: +880 9609004455</a>
                        </div>
                    </nav>
                </header>

            </div>

        )
    }
}
