import 'owl.carousel/dist/assets/owl.carousel.css';
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import {Animated} from "react-animated-css";
import OrderForm from '../slices/OrderForm';

import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Home extends Component {

    componentDidMount(){
        document.title = 'Navana Welding Eletrode Ltd';
  
        // let scripts = [
        //     { src: "public/frontend/js/owl.carousel.min.js" },
        //     { src: "public/frontend/js/aos.js" },
        //     { src: "public/frontend/js/custom.js" }
        // ]

        // scripts.map(item => { 
        //     const script = document.createElement("script")
        //     script.src = item.src
        //     script.async = true
        //     document.body.appendChild(script)
        // })  

        AOS.init({
            duration : 1200
        })
    }
    


    render() {
        return (
            <div>
                
                <section id="hero" className="hero-style-2 d-flex min-vh-100 align-items-center text-white">
                    {/* <div className="circles">
                        <img className="circle-1 d-none d-xl-block" src="images/index-app/hero/circle-1.png" alt="Hero Circle"/>
                        <img className="circle-2 d-none d-xl-block" src="images/index-app/hero/circle-2.png" alt="Hero Circle"/>
                        <img className="circle-3 d-xl-none " src="images/index-app/hero/circle-3.png" alt="Hero Circle"/>
                        <img className="hero-star d-none d-xl-block" src="images/index-app/hero/star.png" alt="Hero Star"/>
                        <img className="hero-dot" src="images/index-app/hero/dot.png" alt="Hero Dot"/>
                    </div> */}
                    <img className="hero-background d-none d-xl-block" src="assets/images/slider/bg-banner.png" alt="Background SVG"/>
                    <div className="container-fluid">
                        <div className="row">
                        <div className="offset-lg-1 col-lg-4 col-md-5 mb-lg-5 align-self-center">
                            <h3>BONDING METAL WITH IDEAS!</h3>
                            <a href="/contact" className="btn bg-white btn-medium"><i className="fas fa-phone"></i> Get in Touch</a>
                        </div>
                        <div className="offset-lg-1 col-lg-5 col-md-7 d-md-block d-xl-block align-items-center my-auto">
                            {/* <img src="images/index-app/hero/purple-people.png" alt="Hero image" className="img-fluid" /> */}
                            <OwlCarousel
                                className="hero-slider owl-theme"
                                autoplay={true}
                                margin={0}
                                loop={true}
                                items ={1}
                                animateIn = "flipInX"
                                animateOut = "slideOutDown"
                                nav ={false}
                                dots= {false}
                            >
                                <div className="item">
                                    <img className="img-fluid" src="assets/images/slider/slider-1.png" alt="NAVANA NEO ARC"/>
                                </div>
                                <div className="item">
                                    <img className="img-fluid" src="assets/images/slider/slider-2.png" alt="NAVANA WELD"/>
                                </div>

                            </OwlCarousel>
                        </div>
                        </div>
                    </div>
                </section>

                <section id="services" className="text-center">
                    <div className="container">
                        <div className="row text-center mb-3">
                            <div className="col">
                                <h5 className="text-primary mb-3">Product</h5>
                                <h3 className="mt-1 heading-color">Our Product Line</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4" data-aos="fade-up">
                                <a href="/product">
                                <div className="service service-style-1">
                                    <img src="assets/images/arc-welding-icon.png" alt="Services" />
                                    <h5 className="service-title">ARC Welding</h5>
                                    <span className="service-text">Most common type of welding is ARC welding which is also known ....</span>
                                </div>
                                </a>
                            </div>
            
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <a href="/mig-welding">
                                <div className="service service-style-1">
                                    <img src="assets/images/MIG-Welding.png" alt="MIG Welding" />
                                    <h5 className="service-title">MIG Welding</h5>
                                    <span className="service-text">MIG Welding is an abbreviation for metal-inert-gas welding.</span>
                                </div>
                                </a>
                            </div>

                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                                <a href="/saw">
                                    <div className="service service-style-1">
                                        <img src="assets/images/submerged-arc-welding-icon.png" alt="submerged arc welding icon" />
                                        <h5 className="service-title">Submerged ARC Welding</h5>
                                        <span className="service-text">Submerged-Arc Welding is mostly used as a  ...</span>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="customers" className="pt-40 p-0" data-aos="zoom-in">
                    <div className="container">
                    <div className="row text-center">
                        <div className="col">
                        {/* <h3 className="mt-1"> Message from Honorable Vice Chairman</h3> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
             
                        <div className="owl-testimonials owl-carousel owl-theme">
               
                            <div className="item testimonial">
                                <img src="assets/images/sajedul_islam_shuvro.jpg" className="quote-image rounded-circle" alt="Quote author"/>
                                <h4> Message from Honorable Vice Chairman</h4>
                                <span className="quote-message">"Since the company’s establishment, NWEL never ceased expanding. It all started with three types electrodes whereas the number has now reached to eleven and still increasing. NWEL has been successful in manufacturing quality products just like all other Navana concerns. NWEL a ISO 9001-2015 company has already bagged in certificates from Lloyd's Register, Bureau Veritas, DNV GL, RINA,IRClass, etc. which bears the testimony of their wide range of welding electrodes with quality. <br/> 
                                Meeting our customer’s need has always been the first and foremost objective. Our grail is to clutch the state of the art manufacturing technology for electrodes with the finest and refined raw materials from various parts of the world. We assure to continuation of offering standardized products at the most competitive price."</span>
                                <div className="quote-info text-right">
                                    <div className="signature-box">
                                    <img src="assets/images/vc-signature.png" className="signature"  alt="Quote author"/>
                                    </div>
                                    <h6 className="quote-author">Mr. Sajedul Islam</h6>
                                    <span className="quote-job">Vice-Chairman </span>
                                </div>
                            </div>
                            <div className="item testimonial">
                                <img src="assets/images/c-photo.png" className="quote-image rounded-circle" alt="Quote author"/>
                                <h4> Message from Honorable Chairman</h4>
                                <span className="quote-message">"After taking the logical steps for Manufacturing, Marketing, Sales & Distributing welding related products,Navana Welding Electrode Ltd.has formed to meet the new challenge.<br/>
                                The vision of Navana Welding Electrode Ltd. is to be a true welding solutions provider by ensuring highest level of customer satisfaction throughout the country via its quality products, manufacturing plants,knowledge based centers of excellence and a comprehensive sales & distribution network. <br/>
                                Our focus is not only on supplying customers with welding consumables but also to provide a complete solution to any welding problem. Our aim is to help customers by developing innovative welding processes, which reduce cost, offer consistent quality, improve the work environment and allow for future expansion. We are pleased to announce our achievement of ISO 9001:2008 certification in this year for quality products and services.<br/>
                                As we strive for excellence in all endeavors, we are confident to be the most competitive welding electrode company in the country for all customer segments. <br/>
                                We wish your kind and continual support for our future development"</span>
                                <div className="quote-info text-right">
                                    <div className="signature-box">
                                        <img src="assets/images/c-signature.png" className="signature"  alt="Quote author"/>
                                    </div>
                                    <h6 className="quote-author">Shafiul Islam Kamal </h6>
                                    <span className="quote-job">Chairman </span>
                                </div>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                    </div>
                </section>

                <OrderForm/>

            </div>
        )
    }
}
