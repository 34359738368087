import React, { Component } from 'react'
import OrderForm from '../../slices/OrderForm';

export default class Spark extends Component {

    componentDidMount () {

        let scripts = [
            { src: "assets/js/magnific-popup.js" },
            { src: "assets/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })  

    }

    render() {

        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="breadcrumb-title"> Navana SPARK </h3>
                        </div>
                    </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        <section id="works">
                            <div className="container">
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="works-grid--1cols">
                                        <div className="work-item pr-item">
                                            <div className="work-container">
                                                <img src="assets/images/products/ns.jpg" alt="Project Preview"/>
                                                <a className="work-mask image-link" href="assets/images/products/ns.jpg" data-popup-type="image">
                                                <div className="work-details">
                                                    <h5 className="work-title"><i className="fas fa-search-plus"></i></h5>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="page page-style-1 service-1 m-0">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark-ns">
                                                <tr>
                                                    <th scope="col" colSpan="3">Classification</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <th scope="row">AWS</th>
                                                <td>A 5.1:</td>
                                                <td>E6012</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p><strong>Applications:</strong> <br/> E-6012 can be used for welding of mild steel; particularly grill making, gates, repair jobs, steel furniture, truck bodies, super structure of ships etc.
                                        </p>
                                        <p><strong>Characteristics:</strong> <br/> A general purpose of mild steel electrode, suitable for use in all positions except vertical down with good penetration property to produce smooth bead of good appearance. It is easy to use, slag removal and no special training is required.</p>

                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-ns">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Chemical Analysis (%)</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">C</th>
                                                        <th>Mn</th>
                                                        <th>Si</th>
                                                        <th>P</th>
                                                        <th>S</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">0.09</th>
                                                        <td>0.52</td>
                                                        <td>0.20</td>
                                                        <td>0.03</td>
                                                        <td>0.03</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-ns">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Mechanical Properties</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Yield Strength</th>
                                                        <th>Tensile Strength</th>
                                                        <th>Reduction in Area</th>
                                                        <th>Elongation</th>
                                                        <th>Impact ( Charpy V Notch)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">447 N/mm <sup>2</sup></th>
                                                        <td>540 N/mm <sup>2</sup></td>
                                                        <td>48%</td>
                                                        <td>29%</td>
                                                        <td>67 Joules at minus 18 <sub>0</sub>C</td>
        
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-ns">
                                                    <tr>
                                                        <th scope="col" colSpan="3">Others</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Storage</th>
                                                        <th>Current Condition</th>
                                                        <th>Welding Position ( All positions except vertical down)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Store in a dry place. Redrying at 110<sup>0</sup>C for 30 minutes is recommended in case of excessive dampness.</th>
                                                        <td>AC Minimum OCV 50 or DC Electrode (+/-)</td>
                                                        <td><img src="assets/images/icon.png" alt=""/></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-ns">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Packing Data</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Size (mm)</th>
                                                        <th>Length (mm)</th>
                                                        <th>Stick per Packet (+-2)</th>
                                                        <th>Kgs. per Packet</th>
                                                        <th>Current Range (Amp.)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>2.5</td>
                                                        <td>350</td>
                                                        <td>182</td>
                                                        <td>3</td>
                                                        <td>70-90</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3.2</td>
                                                        <td>350</td>
                                                        <td>100</td>
                                                        <td>3</td>
                                                        <td>95-125</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4.0</td>
                                                        <td>450</td>
                                                        <td>99</td>
                                                        <td>5</td>
                                                        <td>125-175</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5.0</td>
                                                        <td>450</td>
                                                        <td>66</td>
                                                        <td>5</td>
                                                        <td>165-260</td>
                                                    </tr>
                                                    
                                                    
                                                </tbody>
                                            </table>
                                        </div>

                                        <h6>Special Instructions</h6>
                                        <ul>
                                            <li>Clean the base metals thoroughly, make sure there is no rust, paint and water or grease.</li>
                                            <li>Keep the arc as short as possible.</li>
                                            <li>Follow the safety warning on the body of packet.</li>
                                        </ul>

                                    </div>
                                </div>


                            </div>
                            </div>
                        </section>
                        </div>
                    </div>
                    </div>
                <OrderForm/>
            </div>
        )
    }
}
