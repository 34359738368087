import React, { Component } from 'react'
import { BrowserRouter as Router, Route,Switch, Link } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import QualityPolicy from './pages/QualityPolicy'
import TopManagement from './pages/TopManagement';

import Product from './product/Index';

import MigWelding from './product/MigWelding';
import Saw from './product/Saw';
import Contact from './pages/Contact';
import Career from './pages/Career';
import Order from './pages/Order';
import Gallery from './pages/Gallery';
import Network from './pages/Network';
import News from './pages/News';

import Navanaarc from './product/arc/NavanaARC';
import BondMarine from './product/arc/BondMarine';
import CieNiCuB from './product/arc/CieNiCuB';
import Hard350r from './product/arc/Hard350r';
import Hard650B from './product/arc/Hard650B';
import Pearl from './product/arc/Pearl';
import Spark from './product/arc/Spark';
import Sse308L16 from './product/arc/Sse308L16';
import SuperSpeed from './product/arc/SuperSpeed';
import Weld from './product/arc/Weld';


export default class Myroute extends Component {
    render() {
        return (
    
            <div>
                <Switch>
                    <Route exact path="/" component={Home}></Route>
                    <Route  exact path="/about" component={About}></Route>
                    <Route  exact path="/product" component={Product}></Route>
                    <Route  exact path="/mig-welding" component={MigWelding}></Route>
                    <Route  exact path="/saw" component={Saw}></Route>
                    <Route  exact path="/quality-policy" component={QualityPolicy}></Route>
                    <Route  exact path="/top-management" component={TopManagement}></Route>
                    <Route  exact path="/contact" component={Contact}></Route>
                    <Route  exact path="/order" component={Order}></Route>
                    <Route  exact path="/career" component={Career}></Route>
                    <Route  exact path="/photo-gallery" component={Gallery}></Route>
                    <Route  exact path="/market-network" component={Network}></Route>
                    <Route  exact path="/news" component={News}></Route>

                    <Route  exact path="/navana-arc" component={Navanaarc}></Route>
                    <Route  exact path="/bond-marine" component={BondMarine}></Route>
                    <Route  exact path="/cieNiCuB" component={CieNiCuB}></Route>
                    <Route  exact path="/hard350r" component={Hard350r}></Route>
                    <Route  exact path="/hard650B" component={Hard650B}></Route>
                    <Route  exact path="/pearl" component={Pearl}></Route>
                    <Route  exact path="/spark" component={Spark}></Route>
                    <Route  exact path="/Sse308L16" component={Sse308L16}></Route>
                    <Route  exact path="/wuperspeed" component={SuperSpeed}></Route>
                    <Route  exact path="/weld" component={Weld}></Route>

                </Switch>
            </div>

        )
    }
}
    