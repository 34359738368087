import React, { Component } from 'react'

export default class TopManagement extends Component {
    render() {
        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                        <h3 className="breadcrumb-title">Top Management</h3>
                        </div>
                    </div>
                    </div>
                </section>

                <section id="teams" className="bg-white text-center">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="team-member">
                                <img src="assets/images/team/shafiul_islam_kamal.jpg" className="rounded-circle img-fluid" alt="shafiul islam kamal"/>
                                <h6 className="member-name">Mr. Shafiul Islam Kamal</h6>
                                <span className="member-title">Chairman</span>
                                <div className="overlay">
                                    <a href="#" type="button" className="btn-block-nwel" data-toggle="modal" data-target="#shafiulIslam"><i className="fas fa-search-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="team-member">
                                <img src="assets/images/team/saiful_islam_sumon.jpg" className="rounded-circle img-fluid" alt="shafiul islam kamal"/>
                                <h6 className="member-name">Mr. Saiful Islam</h6>
                                <span className="member-title">Senior Vice-Chairman</span>
                                <div className="overlay">
                                    <a href="#" type="button" className="btn-block-nwel" data-toggle="modal" data-target="#saifulIslam"><i className="fas fa-search-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="team-member">
                                <img src="assets/images/team/sajedul_islam_shuvro.jpg" className="rounded-circle img-fluid" alt="shafiul islam kamal"/>
                                <h6 className="member-name">Mr. Sajedul Islam </h6>
                                <span className="member-title"> Vice-Chairman</span>
                                <div className="overlay">
                                    <a href="#" type="button" className="btn-block-nwel" data-toggle="modal" data-target="#sajedulIslam"><i className="fas fa-search-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="team-member">
                                <img src="assets/images/team/khaleda_islam.jpg" className="rounded-circle img-fluid" alt="shafiul islam kamal"/>
                                <h6 className="member-name">Mrs. Khaleda Islam </h6>
                                <span className="member-title"> Director</span>
                                <div className="overlay">
                                    <a href="#" type="button" className="btn-block-nwel" data-toggle="modal" data-target="#khaledaIslam"><i className="fas fa-search-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="team-member">
                                <img src="assets/images/team/farhana_islam.jpg" className="rounded-circle img-fluid" alt="shafiul islam kamal"/>
                                <h6 className="member-name">Ms. Farhana Islam</h6>
                                <span className="member-title"> Director</span>
                                <div className="overlay">
                                    <a href="#" type="button" className="btn-block-nwel" data-toggle="modal" data-target="#farhanaIslam"><i className="fas fa-search-plus"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>

                <div className="modal fade" id="shafiulIslam" tabIndex="-1" role="dialog" aria-labelledby="shafiulIslamLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-nwel" id="shafiulIslamLabel">Mr. Shafiul Islam Kamal</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body modal-body-nwel">
                            <p>Born in 1949, Mr. Shafiul Islam Kamal, son of Late Alhaj Aftabuddin Ahmed, had his early education in science related subjects. 
                                He joined the Islam Group in 1968 to begin what was to become a successful career in the automotive as well as in construction, 
                                real estate business. In the early years, he gained valuable experience by being involved in the management of the Group’s diverse 
                                business operations. He played a major role in establishing Navana as the premier car company in Bangladesh, and later, in setting up 
                                Aftab Automobiles.</p>

                            <p>He was also responsible for the construction company named: Bengal Development Corporation (BDC) and he was directly responsible for 
                                the construction work of BDC in Middle East and from 1981 he was also taking care Eastern Housing Limited, 
                                then the largest real estate developing company in Bangladesh. In 1996, after the death of Mr. Jahurul Islam, the 
                                then Chairman of Islam Group, he separated from Islam Group with Navana Ltd. and Aftab Automobiles Ltd. and formed Navana Group. 
                                He has been the key person of the business of Islam Group and he was a person responsible to take care all problems and to maintain public 
                                relation with all the top politicians, bureaucrats, top military officials and other key persons. With the background and the amount of 
                                acceptability that he had, nobody else in this country enjoys that</p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="saifulIslam" tabIndex="-1" role="dialog" aria-labelledby="saifulIslamLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-nwel" id="saifulIslamLabel">Mr. Saiful Islam</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body modal-body-nwel">
                            <p>Mr. Saiful Islam (Shumon), son of Mr. Shafiul Islam Kamal, had his education in business related subjects from New Delhi University, 
                                India. While attending his university studies, Mr. Islam joined his father in Navana Group.</p>

                            <p>He gained valuable experience by being involved in the management of the group's diverse business operations. In 2000 Mr. 
                                Islam accepted the responsibilities of the management and operations of Navana Limited, Navana Group's flagship company. 
                                Since this time Mr. Saiful Islam has also contributed his experience and management skills to the operations of Navana Real Estate Limited, Navana Construction
                                 Limited and Navana CNG Ltd</p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="sajedulIslam" tabIndex="-1" role="dialog" aria-labelledby="sajedulIslamLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-nwel" id="sajedulIslamLabel">Mr. Sajedul Islam </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body modal-body-nwel">
                            <p>Mr. Sajedul Islam, second son of Mr. Shafiul Islam Kamal, return from Boston University, USA, and joined Navana Group in 2003. His return to Bangladesh was much anticipated as assumed the leadership and management of Navana Group,
                                 Construction and Real Estate Division.</p>
                            <p>Mr. Sajedul Islam is also very active in the management and operations of the Navana Group flagship company,
                                Navana Limited and most recent Navana CNG Ltd.</p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="khaledaIslam" tabIndex="-1" role="dialog" aria-labelledby="khaledaIslamLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-nwel" id="khaledaIslamLabel">Mrs. Khaleda Islam</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body modal-body-nwel">
                            <p>Mrs. Khaleda Islam is the wife of Mr. Shafiul Islam Kamal, Chairman of the Navana Group. Mrs. Khaleda Islam has experience in business for more than 30 years. At present, Mrs. Khaleda Islam is a Director of Navana Group, consisting of thirty eight companies, involved, amongst others, in vehicles assembling, body building and trading; real estate; 
                                construction; CNG conversion and CNG stations; textiles; car rental; electronics; IT; petroleum and renewable energy.</p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="farhanaIslam" tabIndex="-1" role="dialog" aria-labelledby="farhanaIslamLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-nwel" id="farhanaIslamLabel">Ms. Farhana Islam</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Ms. Farhana Islam, a young enthusiastic entrepreneur of the country, joined the Navana Group in 2004. In the early years, she gained valuable experience by being involved in the management of the Group's diversified business operations. She took various business related professional courses 
                                at home and abroad and has been playing a dynamic role in group businesses.</p>
                        </div>
                        </div>
                    </div>
                </div>



            </div>
        )
    }
}
