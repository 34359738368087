import React, { Component } from 'react'
import OrderForm from '../../slices/OrderForm';

export default class Hard350r extends Component {

    componentDidMount () {

        let scripts = [
            { src: "assets/js/magnific-popup.js" },
            { src: "assets/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })  

    }

    render() {

        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="breadcrumb-title"> Navana Hard 350R </h3>
                        </div>
                    </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        <section id="works">
                            <div className="container">
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="works-grid--1cols">
                                        <div className="work-item pr-item">
                                            <div className="work-container">
                                                <img src="assets/images/products/h360.jpg" alt="Project Preview"/>
                                                <a className="work-mask image-link" href="assets/images/products/h360.jpg" data-popup-type="image">
                                                <div className="work-details">
                                                    <h5 className="work-title"><i className="fas fa-search-plus"></i></h5>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="page page-style-1 service-1 m-0">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark-h360">
                                                <tr>
                                                    <th scope="col">Hardness</th>
                                                    <th scope="col">Around 350 HV</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>

                                        <p><strong>Applications:</strong> <br/> This electrode is recommended for hard facing of mild steel, carbon steel and low alloy steel components, which require maximum hardness within the Machin able range. Typical items include roller, tractor and idler wheels, brake shoes, crane wheels, wobblers, gears, stairs, forging dies, plough shares, conveyor parts, drive sprockets, tie tamping bars and picks.
                                        </p>
                                        <p><strong>Characteristics:</strong> <br/> A heavy coated Rutile based air hardening type electrode which produced weld bead is Machin able only with carbide type tools. The weld deposit has excellent resistance to abrasion and moderate resistance to impact. The electrode has a smooth and stable arc with low spatter loss. It is usable in all positions and deposits a weld with fine bead appearance and easy deslagging properties.</p>

                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-h360">
                                                    <tr>
                                                        <th scope="col" colSpan="3">Others</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Storage</th>
                                                        <th>Current Condition</th>
                                                        <th>Welding Position ( All positions except vertical down)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Store in a dry place. Redrying at 130<sup>0</sup>C for 30-40 minutes is recommended for best results.</th>
                                                        <td>AC Minimum OCV 60 or DC Electrode (+/-)</td>
                                                        <td><img src="assets/images/icon.png" alt=""/></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="my-3">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark-h360">
                                                    <tr>
                                                        <th scope="col" colSpan="5">Packing Data</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Size (mm)</th>
                                                        <th>Length (mm)</th>
                                                        <th>Stick per Packet (+-2)</th>
                                                        <th>Kgs. per Packet</th>
                                                        <th>Current Range (Amp.)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>3.2</td>
                                                        <td>450</td>
                                                        <td>82</td>
                                                        <td>3</td>
                                                        <td>90-120</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4.0</td>
                                                        <td>450</td>
                                                        <td>55</td>
                                                        <td>3</td>
                                                        <td>130-170</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>

                                        <h6>Special Instructions</h6>
                                        <ul>
                                            <li>Clean the base metals thoroughly, make sure there is no rust, paint and water or grease.</li>
                                            <li>Keep the arc as short as possible.</li>
                                            <li>Follow the safety warning on the body of packet.</li>
                                        </ul>

                                    </div>
                                </div>


                            </div>
                            </div>
                        </section>
                        </div>
                    </div>
                    </div>
            <OrderForm/>
            </div>
        )
    }
}
