import React, { Component } from 'react'
import OrderForm from '../slices/OrderForm';

export default class MigWelding extends Component {
    render() {
        return (
            <div>
                <section id="breadcrumb" className="bg-primary text-center text-white">
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="breadcrumb-title">MIG Welding</h3>
                        </div>
                    </div>
                    </div>
                </section>

                <section>
                    <div className="container">

                        <div className="row post">
                            <div className="col-4 post-image">
                                <img className="img-fluid" src="assets/images/products/2-1.jpg" alt="post"/>
                            </div>
                            <div className="col-8 post-content">
                                <div className="post-text">
                                    <p>MIG Welding is an abbreviation for metal-inert-gas welding. It is also known as GMAW, which stands for gas-metal-arc welding. In this process, coalescence is achieved by an electric arc formed between the work piece and a continuous consumable solid wire electrode which is fed through a gun at controlled speeds. Inert gas usually argon or argon mixture flows through the gun and forms a blanket over the weld puddle to protect it from atmospheric contamination.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
                <OrderForm/>
            </div>
        )
    }
}
